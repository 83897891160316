.login-drawer {
  .backdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.39);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1999;
    transition: all 0.5s ease;
  }
}
.content-drawer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background-color: white;
  //overflow: auto;
  z-index: 2000;
  // border-radius: 12px 12px 0px 0px;
  border-radius: 0;
  // transition: transform 0.5s ease;
  // transition: transform 0.3s ease, opacity 0.3s ease;
  animation-name: cart-right;
	animation-duration: 2s;
  transition:
			transform 1s ease,
			opacity 0.3s ease;
  @include desktop {
    // left: 78%;
    // border-radius: 12px 0px 0px 12px;
    left: unset;
    border-radius: 0;
    width: 440px;
  }
  @include ipadPro {
    // left: 67%;
    left: unset;
    border-radius: 0;
    width: 440px;
  }
  @include tab{
    left: unset;
    border-radius: 0;
    width: 440px;
  }
  @include ipadLandscape {
    // left: 67%;
    left: unset;
    border-radius: 0;
    width: 440px;
  }
  // @include tab {
  //   left: 60%;
  // }
  .top-sec {
    padding: 18px;
    background: var(--primary);
    justify-content: center;
    // border-radius: 12px 12px 0px 0px;
    border-radius: 0;
    @include desktop {
      // border-radius: 12px 0px 0px 0px;
    }
    h2 {
      color: #ffffff;
      // font-size: 28px;
      // line-height: 34px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      @include desktop {
        font-size: 20px;
        line-height: 24px;
      }
    }
    i {
      display: none;
      color: #ffffff;
      @include desktop {
        font-size: 26px;
        display: block;
      }
    }
  }
  .new-login-component {
    padding: 0px;
    min-height: unset;
    .right-sec {
      // padding: 0 25px;
      margin-top: 2rem;
      max-width: unset;
      min-width: unset;
      width: 100%;
      @include desktop {
        margin-top: 5rem;
      }
      .form-group {
        .mini-label {
          color: #999999;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 3px;
        }
        .phone-number,
        .otp-input {
          height: 42px;
          border-radius: 8px !important;
          outline: none;
          border: 1px solid #ced4da !important;
        }
        .phone-number:focus,
        .otp-input:focus {
          box-shadow: none;
          border: 1px solid #ced4da !important;
        }
        .input-box-icon {
          .inline-loader {
            bottom: 0;
            transform: translateY(100%);
            &::after {
              top: 38%;
            }
          }
        }
      }
      .img-container {
        display: none;
      }
      .otp-on-call {
        .btn-primary {
          width: auto;
          min-width: unset;
        }
      }
    }
  }
  .login-banner-sec {
    // height: 229px;
    // padding: 0 20px;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    @include desktop {
      bottom: 25px;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}

@keyframes cart-right {
  0% {
    right: -100px;
    left: auto;
  }

  100% {
    right: 0px;
    left: auto;
  }
}

.anim-drawer-enter {
  .content-drawer {
    transform: translateX(100%);
    @include desktop {
      transform: translateX(100%);
    }
  }
  .backdrop {
    display: none;
  }
}
.anim-drawer-enter-active,
.anim-drawer-enter-done {
  .content-drawer {
    transform: translateX(0%);
    @include desktop {
      transform: translateX(0%);
    }
  }
  .backdrop {
    display: block;
  }
}
.anim-drawer-exit .content-drawer {
  transform: translateX(0%);
  @include desktop {
    transform: translateX(0%);
  }
}
.anim-drawer-exit-active,
.anim-drawer-exit-done {
  .content-drawer {
    transform: translateX(100%);
    @include desktop {
      transform: translateX(100%);
    }
  }
  .backdrop {
    display: none;
  }
}
